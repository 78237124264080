import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import './AddEditUserDlg.scss';
import { Button, ModalDialog, TextField } from '@csdental/react-components';
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Box,
  MenuItem,
  DialogActions,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ROLES, UsersScopes } from '../../service/roleManager/roleDefinitions';
import { hasPermission } from '../../service/roleManager/roleManager';
import { IUserRecord } from '../../features/users/usersTypes';
import { useGetAccountQuery } from '../../features/account/accountApi';
import { IAddEditDlgProps } from '../../common/types/dialogTypes';
import { useGetLocationsQuery } from '../../features/locations/locationsAPI';
import { useAppSelector } from '../../common/hooks/state';
import { getSelectedTenant } from '../../features/tenants/tenantsSlice';

// eslint-disable-next-line no-shadow, no-unused-vars
enum EProfile {
  // eslint-disable-next-line no-unused-vars
  Staff = 'Staff',
  // eslint-disable-next-line no-unused-vars
  Administrator = 'Administrator',
  // eslint-disable-next-line no-unused-vars
  Assistant = 'Assistant',
  // eslint-disable-next-line no-unused-vars
  Doctor = 'Doctor',
  // eslint-disable-next-line no-unused-vars
  Hygienist = 'Hygienist',
  // eslint-disable-next-line no-unused-vars
  Unknown = 'Unknown',
}

const AddEditUserForm = ({ ...inputProps }: IAddEditDlgProps<IUserRecord>) => {
  const { t } = useTranslation();

  const [fn, setFn] = useState<string | undefined>(inputProps.data.firstName || undefined);
  const [ln, setLn] = useState<string | undefined>(inputProps.data.lastName || undefined);
  const [em, setEm] = useState<string | undefined>(inputProps.data.emailAddress || undefined);
  const [pr, setPr] = useState<string | undefined>(inputProps.data.roleTypeCode || undefined);
  const [rl, setRl] = useState<string | undefined>(inputProps.data.wallachiaUserRole || undefined);
  const [lo, setLo] = useState<string | undefined>(inputProps.data.defaultLocationId || undefined);
  const [canLogin, setCanLogin] = useState<boolean | undefined>(inputProps.data.canLogin || undefined);
  const [validated, setValidated] = useState(false);
  const [fnError, setFnError] = useState<boolean>();
  const [lnError, setLnError] = useState<boolean>();
  const [emError, setEmError] = useState<boolean>();
  const [prError, setPrError] = useState<boolean>();
  const [rlError, setRlError] = useState<boolean>();
  const [loError, setLoError] = useState<boolean>();

  const { data: account } = useGetAccountQuery();
  const selectedTenant = useAppSelector((state) => getSelectedTenant(state));

  // Call the API to get the available locations
  const { data: locationData, isLoading } = useGetLocationsQuery(account ? {
    skip: 0,
    top: 100,
    filter: '',
  } : skipToken);

  const canManageAdmins = hasPermission(selectedTenant, [UsersScopes.ManageAdministrators]);

  const permittedRoles = (Object.keys(ROLES) as Array<keyof typeof ROLES>).filter((role) => {
    // Only show the Admin role for Users who can mange admins (Account Owners)
    if (role === ROLES.Administrator && !canManageAdmins) return false;
    // Only show the Account Owner role when editing an Account Owner
    if (role === ROLES.AccountOwner) {
      if (inputProps.isCurrent && inputProps.data.wallachiaUserRole === ROLES.AccountOwner) {
        return true;
      }
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (undefined !== fn) setFnError(fn.length === 0);
  }, [fn]);

  useEffect(() => {
    if (undefined !== ln) setLnError(ln.length === 0);
  }, [ln]);

  useEffect(() => {
    if (undefined !== em) setEmError((em.length === 0) || !isEmail(em));
  }, [em]);

  useEffect(() => {
    if (undefined !== pr) setPrError(pr === EProfile.Unknown);
  }, [pr]);

  useEffect(() => {
    if (undefined !== rl) setRlError(rl === ROLES.Unknown);
  }, [rl]);

  useEffect(() => {
    if (undefined !== lo) setLoError(lo.length === 0);
  }, [lo]);

  useEffect(() => {
    // check if errors are undefined to avoid manage init state when there is no field
    // completed and validate must be set to its default value
    if (undefined !== fnError
      && undefined !== lnError
      && undefined !== emError
      && undefined !== prError
      && undefined !== rlError) {
      setValidated(!(fnError || lnError || emError || prError || rlError));
    }
  }, [fnError, lnError, emError, prError, rlError]);

  return (
    <div className="addEditUser">
      <div className="user-Legend">
        <span>* </span>
        {t('Mandatory fields')}
      </div>
      <Box
        component="form"
        sx={{
          maxWidth: '100%',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          value={fn}
          error={fnError}
          fullWidth
          required
          margin="normal"
          size="small"
          id="user-firstname"
          label={t('Firstname')}
          variant="outlined"
          onChange={(e) => setFn(e.target.value)}
          helperText={fnError ? t('The firstname is required.') : ''}
        />

        <TextField
          value={ln}
          error={lnError}
          fullWidth
          required
          margin="normal"
          size="small"
          id="user-lastname"
          label={t('Last name')}
          variant="outlined"
          onChange={(e) => setLn(e.target.value)}
          helperText={lnError ? t('The last name is required.') : ''}
        />

        <TextField
          value={em}
          error={emError}
          fullWidth
          required
          margin="normal"
          size="small"
          id="user-email"
          label={t('Email')}
          variant="outlined"
          onChange={(e) => setEm(e.target.value)}
          helperText={emError ? t('The email is invalid.') : ''}
          disabled={inputProps.isEditMode}
        />
        {
          (inputProps.error?.toLowerCase().includes('email address')
            || inputProps.error?.toLowerCase().includes('failed to create a user')) && (
            <p className="user-Error_Text">
              {t('Failed to create user')}
            </p>
          )
        }

        <div className="user-account-status">
          <label className="addEditUser-bold" htmlFor="userAccountStatus">
            {t('Account status')}
            <span> * </span>
          </label>
          <FormGroup className="account-status">
            <FormControlLabel
              control={(
                <Switch
                  size="small"
                  checked={canLogin}
                  onChange={(e) => setCanLogin(e.target.checked)}
                  // TODO: This customisation could be moved into the
                  // shared component library.
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      color: '#edb842',
                      '&.Mui-checked': {
                        color: '#6fbe52',
                      },
                    },
                  }}
                  disabled={inputProps.isCurrent || !inputProps.isEditMode}
                />
              )}
              label={canLogin ? t('Active') : t('Inactive')}
            />
          </FormGroup>
        </div>

        <div className="user-profile">
          <TextField
            error={prError}
            select
            fullWidth
            required
            size="small"
            id="user-tenant"
            label={t('Profile')}
            variant="outlined"
            defaultValue=""
            value={!pr ? '' : pr}
            onChange={(e) => setPr(e.target.value as EProfile)}
            helperText={prError ? t('The profile is required.') : ''}
          >
            {(Object.keys(EProfile) as Array<keyof typeof EProfile>).map(
              (key) => ((key !== EProfile.Unknown)
                ? (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ) : null
              ),
            )}
          </TextField>
        </div>
        <div className="user-role">
          <TextField
            error={rlError}
            select
            fullWidth
            required
            size="small"
            id="user-role"
            label={t('User Role')}
            variant="outlined"
            defaultValue=""
            value={!rl ? '' : rl}
            onChange={(e) => setRl(e.target.value as ROLES)}
            helperText={rlError ? t('The user role is required.') : ''}
            disabled={inputProps.isCurrent}
          >
            {(Object.values(permittedRoles) as Array<keyof typeof ROLES>).map(
              (key) => ((key !== ROLES.Unknown)
                ? (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ) : null
              ),
            )}
          </TextField>
        </div>

        <h4>{t('Location')}</h4>
        <div className="user-location">
          <TextField
            error={loError}
            select
            fullWidth
            required
            size="small"
            id="location"
            label={t('Location')}
            variant="outlined"
            onChange={(e) => setLo(e.target.value)}
            defaultValue=""
            value={!lo ? '' : lo}
            helperText={loError ? t('Location is required.') : ''}
          >
            {isLoading === false ? locationData?.value?.map((location) => (
              <MenuItem key={location.recordId} value={location.recordId}>
                {location.businessName}
              </MenuItem>
            )) : <div />}
          </TextField>
        </div>

        <DialogActions>
          <Button
            label={t('Cancel')}
            onClick={inputProps.onCancel}
            theme="secondary"
          />
          <Button
            label={inputProps.isEditMode ? t('Edit') : t('Invite')}
            disable={!validated}
            onClick={() => {
              if (fn && ln && em && pr && rl) {
                inputProps.onValidate({
                  firstName: fn,
                  lastName: ln,
                  emailAddress: em,
                  roleTypeCode: pr,
                  wallachiaUserRole: rl,
                  defaultLocationId: lo,
                  recordId: inputProps.data.recordId,
                  canLogin,
                });
              }
            }}
          />
        </DialogActions>
      </Box>
    </div>
  );
};

const AddEditUserDlg = ({ ...inputProps }: IAddEditDlgProps<IUserRecord>) => {
  const { t } = useTranslation();

  return (
    <ModalDialog show={inputProps.show} title={inputProps.isEditMode ? t('Edit user') : t('Invite a user')}>
      {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        inputProps.show ? <AddEditUserForm {...inputProps} /> : <></>
      }
    </ModalDialog>
  );
};

export default AddEditUserDlg;
