import React, { useCallback } from 'react';
import { Button } from '@csdental/react-components';
import { Trans, useTranslation } from 'react-i18next';
import './Login.scss';
import { Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import authService from '../../features/auth/authService';
import appLogo from '../../assets/images/csd-logo.svg';
import { useAppSelector } from '../../common/hooks/state';

const Login = () => {
  const { t } = useTranslation();

  // Get the timed out value from auth state
  const timedOut = useAppSelector((state) => state.auth.timedOut);

  const doLogin = useCallback(() => {
    authService.signIn();
  }, []);

  return (
    <div className="login">
      <div className="csdFlow">
        <div className="centered" style={{ flex: 1, flexDirection: 'column' }}>
          <div>
            <img
              src={appLogo}
              alt="Carestream Dental Logo"
              style={{ width: 370 }}
            />
          </div>

          <h3 className="csd-purple" style={{ paddingTop: 50 }}>
            {t('Web Admin Portal')}
          </h3>

          <section style={{ paddingTop: 40, textAlign: 'center' }}>
            {timedOut && <Typography><strong>{t('Your session has timed out.')}</strong></Typography>}
            <Typography>
              <Trans>
                In order to use this application, you must login using your
                {' '}
                <strong>Carestream Dental</strong>
                {' '}
                account.
              </Trans>
            </Typography>
          </section>

          <div style={{ paddingTop: 40 }}>
            <Button
              label={t('LOGIN')}
              onClick={doLogin}
              icon={<ExitToAppIcon />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
