import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Table, Text, SearchBar, IconButton,
} from '@csdental/react-components';
import './Users.scss';
import '../../index.scss';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import AddEditUserDlg from '../../components/AddEditUserDlg';
import RoleManager, { hasPermission } from '../../service/roleManager/roleManager';
import { ROLES, UsersScopes } from '../../service/roleManager/roleDefinitions';
import { IUserRecord } from '../../features/users/usersTypes';
import {
  useAddUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from '../../features/users/usersAPI';
import {
  AddEditDlgStateActions,
  AddEditDlgStateActionsType,
  IAddEditDlgProps,
} from '../../common/types/dialogTypes';
import {
  useGetAccountQuery,
  useGetAccountSettingsQuery,
} from '../../features/account/accountApi';
import { isErrorWithMessage, isFetchBaseQueryError } from '../../common/helpers/errorTypeChecker';

import checkIcon from '../../assets/images/check.svg';
import invitedIcon from '../../assets/images/invited.svg';
import editIcon from '../../assets/images/edit.svg';
import activeIcon from '../../assets/images/active.svg';
import inactiveIcon from '../../assets/images/inactive.svg';
import { useAppSelector } from '../../common/hooks/state';
import { getSelectedTenant } from '../../features/tenants/tenantsSlice';
import { AddressTypeCodes } from '../../features/locations/locationsTypes';

export const Users = () => {
  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();
  const { data: settings } = useGetAccountSettingsQuery();
  const selectedTenant = useAppSelector((state) => getSelectedTenant(state));

  const canManageAdmins = hasPermission(selectedTenant, [UsersScopes.ManageAdministrators]);

  const { t } = useTranslation();

  // Set the default search params
  const [searchValueTmp, setSearchValueTmp] = useState<string>('');
  const [searchParams, setSearchParams] = useState({
    rowsPerPage: settings?.userRowsPerPage ? settings.userRowsPerPage : 10,
    startOffset: 0,
    searchValue: '',
  });

  const initialUserState: Partial<IUserRecord> = {
    recordId: undefined,
    emailAddress: undefined,
    firstName: undefined,
    lastName: undefined,
    roleTypeCode: undefined,
    wallachiaUserRole: undefined,
    canLogin: true,
    defaultLocationId: undefined,
    location: undefined,
  };

  const initialDialogState: IAddEditDlgProps<IUserRecord> = {
    show: false,
    isCurrent: false,
    onValidate: () => { },
    onCancel: () => { },
    isEditMode: false,
    error: '',
    data: initialUserState,
  };

  function dialogReducer(
    state: IAddEditDlgProps<IUserRecord>,
    action: AddEditDlgStateActionsType<IUserRecord>,
  ): IAddEditDlgProps<IUserRecord> {
    switch (action.type) {
      case AddEditDlgStateActions.RESET:
        return initialDialogState;
      case AddEditDlgStateActions.ERROR:
        return {
          ...state,
          error: action.error,
        };
      case AddEditDlgStateActions.ADD:
        return {
          ...state,
          onValidate: action.onValidate,
          onCancel: action.onCancel,
          show: true,
        };
      case AddEditDlgStateActions.EDIT:
        return {
          ...state,
          data: action.data,
          onValidate: action.onValidate,
          onCancel: action.onCancel,
          isCurrent: action.isCurrent,
          isEditMode: true,
          show: true,
        };
      default:
        throw new Error('Invalid state action');
    }
  }

  /** Set initial User Dialog Parameter state */
  const [dialogState, setDialogState] = React.useReducer(
    dialogReducer,
    initialDialogState,
  );

  // Call the API to get users
  const { data: result, isLoading } = useGetUsersQuery(account ? {
    skip: searchParams.startOffset,
    top: searchParams.rowsPerPage,
    filter: searchParams.searchValue,
  } : skipToken);

  // Add User Api trigger
  const [addUserTrigger] = useAddUserMutation();

  // Update User Api trigger
  const [updateUserTrigger] = useUpdateUserMutation();

  const addUser = async (user: Partial<IUserRecord>) => {
    try {
      await addUserTrigger(user).unwrap();

      setDialogState({ type: AddEditDlgStateActions.RESET });

      // Go to the first page
      setSearchParams({
        ...searchParams,
        startOffset: 0,
      });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
        setDialogState({ type: AddEditDlgStateActions.ERROR, error: errMsg });
      } else if (isErrorWithMessage(error)) {
        setDialogState({ type: AddEditDlgStateActions.ERROR, error: error.message });
      }
    }
  };

  const updateUser = async (user: Partial<IUserRecord>) => {
    try {
      await updateUserTrigger(user).unwrap();
      setDialogState({ type: AddEditDlgStateActions.RESET });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
        setDialogState({ type: AddEditDlgStateActions.ERROR, error: errMsg });
      } else if (isErrorWithMessage(error)) {
        setDialogState({ type: AddEditDlgStateActions.ERROR, error: error.message });
      }
    }
  };

  const addUserCancel = () => {
    setDialogState({ type: AddEditDlgStateActions.RESET });
  };

  const getPage = (startOffset: number): number => Math.floor(startOffset / searchParams.rowsPerPage);

  // change page with previous with 0 result in current page
  useEffect(() => {
    if (result?.value.length === 0 && (searchParams.startOffset >= searchParams.rowsPerPage) && !isLoading) {
      // Go to the previous page
      setSearchParams({ ...searchParams, startOffset: searchParams.startOffset - searchParams.rowsPerPage });
    }
  }, [result?.value, isLoading]);

  const tableData = result?.value.map((items: IUserRecord) => {
    const maps: { [key: string]: any } = {};

    maps[t('username')] = {
      props: {
        label: `${items.firstName} ${items.lastName}`,
      },
      render: <Text />,
    };

    maps[t('email')] = {
      props: {
        label: items.emailAddress,
      },
      render: <Text />,
    };

    maps[t('SSOStatus')] = {
      props: {},
      render: (
        <img
          alt={items.userOId ? t('Registered') : t('Invited')}
          src={items.userOId ? checkIcon : invitedIcon}
        />
      ),
    };

    maps[t('Account status')] = {
      props: {},
      render: (
        <div className="users_account-status">
          <img
            className="users_account-status-icon"
            alt={items.canLogin ? t('Active') : t('Inactive')}
            src={items.canLogin ? activeIcon : inactiveIcon}
          />
          <Text
            label={items.canLogin ? t('Active') : t('Inactive')}
            classname={items.webUserId ? 'users_account-status-registered' : 'users_account-status-invited'}
          />
        </div>
      ),
    };

    maps[t('profile')] = {
      props: {
        label: t(items.roleTypeCode),
      },
      render: <Text />,
    };

    maps[t('role')] = {
      props: {
        label: t(items.wallachiaUserRole),
      },
      render: <Text />,
    };

    maps[t('location')] = {
      props: {
      },
      render: (
        <div>
          {(items.location) && (
            <div>
              {items.location.businessName}
              <br />
              {(items.location.addresses && items.location.addresses) && (
                <>
                  {
                    items.location.addresses.find(
                      (address) => address.addressTypeCode === AddressTypeCodes.PHYSICAL,
                    )?.addressBlock
                  }
                </>
              )}
              <br />
            </div>
          )}
        </div>
      ),
    };

    const isCurrentUser = items.recordId === account?.recordId;

    maps[t('actions')] = {
      props: {},
      render: (
        <div>
          <RoleManager scopes={[UsersScopes.canEdit]}>
            {(items.wallachiaUserRole === ROLES.User
              || (items.wallachiaUserRole === ROLES.Administrator && canManageAdmins)
              || (items.wallachiaUserRole === ROLES.AccountOwner && isCurrentUser))
              && (
                <IconButton
                  alt={t('Edit user')}
                  onClick={() => {
                    setDialogState({
                      type: AddEditDlgStateActions.EDIT,
                      data: {
                        recordId: items.recordId,
                        firstName: items.firstName,
                        lastName: items.lastName,
                        emailAddress: items.emailAddress,
                        roleTypeCode: items.roleTypeCode,
                        wallachiaUserRole: items.wallachiaUserRole,
                        defaultLocationId: items.defaultLocationId,
                        canLogin: items.canLogin,
                      },
                      isCurrent: isCurrentUser,
                      onValidate: updateUser,
                      onCancel: addUserCancel,
                    });
                  }}
                  img={editIcon}
                  tooltipText={t('Edit user')}
                />
              )}
          </RoleManager>
        </div>
      ),
    };

    return maps;
  });

  const onSearch = () => {
    setSearchParams({
      ...searchParams,
      startOffset: 0,
      searchValue: searchValueTmp,
    });
  };

  const onClickPagination = (startOffset: number, rowsPerPage: number) => {
    setSearchParams({
      ...searchParams,
      rowsPerPage,
      startOffset,
    });
  };

  const tableColumns = [
    { title: t('username'), width: 100 },
    { title: t('email'), width: 250 },
    { title: t('SSOStatus'), width: 80 },
    { title: t('Account status'), width: 100 },
    { title: t('profile'), width: 120 },
    { title: t('role'), width: 120 },
    { title: t('location'), width: 250 },
    { title: t('actions'), width: 100 },
  ];

  return (
    <div>
      <div className="searchbar">
        <SearchBar
          className="searchbar-input"
          placeholder={`${t('username')}`}
          onChange={(value: string) => setSearchValueTmp(value)}
          onSearch={onSearch}
          onCancel={() => {
            setSearchParams({
              ...searchParams,
              startOffset: 0,
              searchValue: '',
            });
            setSearchValueTmp('');
          }}
          value=""
        />
        <Button
          className="searchbar-btn"
          label={t('Search')}
          onClick={onSearch}
        />
      </div>
      <div>
        <p className="users_table-result">
          {`${result?.['@odata.count']} ${result?.['@odata.count'] === 1
            ? t('result')
            : t('results')}`}
        </p>
      </div>
      <div className="users_add-user">
        <RoleManager scopes={[UsersScopes.canCreate]}>
          <Button
            label={`+ ${t('Invite user')}`}
            onClick={() => {
              setDialogState({
                type: AddEditDlgStateActions.ADD,
                onValidate: addUser,
                onCancel: addUserCancel,
              });
            }}
          />
        </RoleManager>
      </div>
      <Table
        name="users"
        rows={tableData}
        columns={tableColumns}
        nbResult={result?.['@odata.count'] ? result?.['@odata.count'] : 0}
        onClick={onClickPagination}
        displayNumber={false}
        selectedRowsPerPage={searchParams.rowsPerPage}
        rowsPerPageOptions={[10, 50, 100]}
        labelRowsPerPage={t('Users per page')}
        currentPage={getPage(searchParams.startOffset)}
      />
      <AddEditUserDlg
        {...dialogState}
      />
    </div>
  );
};

export default Users;
