import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Text } from '@csdental/react-components';
import './Locations.scss';
import '../../index.scss';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { AddressTypeCodes, ILocationRecord } from '../../features/locations/locationsTypes';
import { useGetLocationsQuery } from '../../features/locations/locationsAPI';
import {
  useGetAccountQuery,
  useGetAccountSettingsQuery,
} from '../../features/account/accountApi';

export const Locations = () => {
  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();
  const { data: settings } = useGetAccountSettingsQuery();

  const { t } = useTranslation();

  // Set the default search params
  // const [searchValueTmp, setSearchValueTmp] = useState<string>('');
  const [searchParams, setSearchParams] = useState({
    rowsPerPage: settings?.userRowsPerPage ? settings.userRowsPerPage : 10,
    startOffset: 0,
    searchValue: '',
  });

  // Call the API to get locations
  const { data: locationData, isLoading } = useGetLocationsQuery(account ? {
    skip: searchParams.startOffset,
    top: searchParams.rowsPerPage,
    filter: searchParams.searchValue,
  } : skipToken);

  const getPage = (startOffset: number): number => Math.floor(startOffset / searchParams.rowsPerPage);

  // change page with previous with 0 result in current page
  useEffect(() => {
    if (locationData?.value.length === 0 && (searchParams.startOffset >= searchParams.rowsPerPage) && !isLoading) {
      // Go to the previous page
      setSearchParams({ ...searchParams, startOffset: searchParams.startOffset - searchParams.rowsPerPage });
    }
  }, [locationData?.value, isLoading]);

  const tableData = locationData?.value.map((items: ILocationRecord) => {
    const maps: { [key: string]: any } = {};

    maps[t('location')] = {
      props: {
      },
      render: (
        <div>
          {items.businessName}
        </div>
      ),
    };

    maps[t('practiceEmail')] = {
      props: {
        label: items.practiceEmail,
      },
      render: <Text />,
    };

    maps[t('defaultAddress')] = {
      props: {
      },
      render: (
        <div>
          {(items.addresses) && (
            <>
              {
                items.addresses.find(
                  (address) => address.addressTypeCode === AddressTypeCodes.PHYSICAL,
                )?.addressBlock
              }
            </>
          )}
          <br />
        </div>
      ),
    };

    maps[t('actions')] = {
      props: {},
      render: (
        <div />
      ),
    };

    return maps;
  });

  const onClickPagination = (startOffset: number, rowsPerPage: number) => {
    setSearchParams({
      ...searchParams,
      rowsPerPage,
      startOffset,
    });
  };

  const tableColumns = [
    { title: t('location'), width: 250 },
    { title: t('practiceEmail'), width: 250 },
    { title: t('defaultAddress'), width: 250 },
    { title: t('actions'), width: 100 },
  ];

  return (
    <div>
      <div>
        <p className="locations_table-result">
          {`${locationData?.['@odata.count']} ${locationData?.['@odata.count'] === 1
            ? t('result')
            : t('results')}`}
        </p>
      </div>
      {/* <div className="locations_add-location">
        <RoleManager scopes={[LocationsScopes.canCreate]}>
          <Button
            label={`+ ${t('Add Location')}`}
            onClick={() => {
              setDialogState({
                type: AddEditDlgStateActions.ADD,
                onValidate: addLocation,
                onCancel: addLocationCancel,
              });
            }}
          />
        </RoleManager>
      </div> */}
      <Table
        name="locations"
        rows={tableData}
        columns={tableColumns}
        nbResult={locationData?.['@odata.count'] ? locationData?.['@odata.count'] : 0}
        onClick={onClickPagination}
        displayNumber={false}
        selectedRowsPerPage={searchParams.rowsPerPage}
        rowsPerPageOptions={[10, 50, 100]}
        labelRowsPerPage={t('Locations per page')}
        currentPage={getPage(searchParams.startOffset)}
      />
    </div>
  );
};

export default Locations;
