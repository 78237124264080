import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import pages from '../../page';
import HeaderComponent from '../Header/Header';
import { IdleTimer } from '../IdleTimer/IdleTimer';
import { useAppDispatch, useAppSelector } from '../../common/hooks/state';
import authService from '../../features/auth/authService';
import { getCurrentAuthState, signOut } from '../../features/auth/authSlice';
import { AuthStates } from '../../features/auth/authTypes';
import SelectTenant from '../../page/SelectTenant';
import { resetTenants } from '../../features/tenants/tenantsSlice';

const {
  Home,
  Users,
  Locations,
  Login,
} = pages;

const NotLoggedIn = () => (
  <Routes>
    <Route path="/" element={<Login />} />
  </Routes>
);

const LoggedIn = () => {
  const dispatch = useAppDispatch();

  const handleTimeout = (isTimedOut: boolean) => {
    dispatch(resetTenants());
    dispatch(signOut({ timedOut: isTimedOut }));
    authService.signOut();
  };

  // Check the current auth state
  const authState = useAppSelector((state) => getCurrentAuthState(state));

  if (authState === AuthStates.LOADING) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div>
      <IdleTimer onTimeout={handleTimeout} />
      <SelectTenant>
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Locations" element={<Locations />} />
        </Routes>
      </SelectTenant>
    </div>
  );
};

const AppRoutes = () => (
  <div>
    <AuthenticatedTemplate>
      <LoggedIn />
    </AuthenticatedTemplate>

    <UnauthenticatedTemplate>
      <NotLoggedIn />
    </UnauthenticatedTemplate>
  </div>
);

export default AppRoutes;
