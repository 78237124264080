import { PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { loginRequest, msalConfig, authScopes } from './authConfig';

class AuthService {
  msalConfig: any;

  msalClient: PublicClientApplication;

  constructor(configuration: any) {
    this.msalConfig = configuration;

    this.msalClient = new PublicClientApplication(this.msalConfig);
  }

  async signIn() {
    this.msalClient.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  }

  signOut() {
    this.msalClient.logoutRedirect();
  }

  /** Get a new auth token for Sensei */
  async getSenseiAuthToken() {
    const token = await this.getAuthTokenForScopes([authScopes.senseiDefault]);
    return token;
  }

  /** Get a new auth token for Wallachia */
  async getWallachiaAuthToken() {
    const token = await this.getAuthTokenForScopes([authScopes.wallachiaUserStandard]);
    return token;
  }

  /** Get a new auth token for the specified scopes */
  async getAuthTokenForScopes(scopes: string[]) {
    const accounts = this.msalClient.getAllAccounts();

    if (accounts.length > 0) {
      const request: SilentRequest = {
        scopes,
        account: accounts[0],
      };

      const accessToken = await this.msalClient.acquireTokenSilent(request)
        .then((response) => response.accessToken).catch((error) => {
          // Do not fallback to interaction when running outside the context of MsalProvider.
          // Interaction should always be done inside context.
          console.log(error);
          return null;
        });

      return accessToken;
    }

    return null;
  }
}

const authService = new AuthService(msalConfig);

export default authService;
