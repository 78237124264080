import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  AuthStates,
  IAuthState,
  ISignInSuccessPayload,
  ISignOutPayload,
} from './authTypes';

const initialState: IAuthState = {
  token: '',
  senseiToken: '',
  loading: true,
  success: false,
  error: false,
  timedOut: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state: IAuthState) => {
      state.token = '';
      state.senseiToken = '';
      state.loading = true;
    },
    signInSuccess: (state, action: PayloadAction<ISignInSuccessPayload>) => {
      state.token = action.payload.token;
      state.senseiToken = action.payload.senseiToken;
      state.loading = false;
      state.success = true;
      state.timedOut = false;
    },
    signOut: (state, action: PayloadAction<ISignOutPayload>) => {
      state.token = '';
      state.senseiToken = '';
      state.loading = false;
      state.success = false;
      state.timedOut = action.payload.timedOut;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signInSuccess, signOut } = authSlice.actions;
export default authSlice.reducer;

export const getCurrentToken = (state: RootState) => state.auth.token;

export const getCurrentAuthState = (state: RootState) => {
  if (state.auth.loading) {
    return AuthStates.LOADING;
  }
  if (state.auth.success) {
    return AuthStates.SUCCESS;
  }
  return AuthStates.ERROR;
};
