import React from 'react';
import { NavMenu } from '@csdental/react-components';
import { useTranslation } from 'react-i18next';

import Users from '../Users';

import usersOffIcon from '../../assets/images/users-off.svg';
import usersOnIcon from '../../assets/images/users-on.svg';

import './Home.scss';
import Locations from '../Locations';
import { hasPermission } from '../../service/roleManager/roleManager';
import { LocationsScopes } from '../../service/roleManager/roleDefinitions';
import { useAppSelector } from '../../common/hooks/state';
import { getSelectedTenant } from '../../features/tenants/tenantsSlice';

const Home = () => {
  const { t } = useTranslation();

  const selectedTenant = useAppSelector((state) => getSelectedTenant(state));

  const canManageLocations = hasPermission(selectedTenant, [LocationsScopes.ManageLocations]);

  const subNavItems = [{
    label: t('Users'),
    render: Users,
  }];

  if (canManageLocations) {
    subNavItems.push({
      label: 'Locations',
      render: Locations,
    });
  }

  const navItems = [
    {
      label: t('Users'),
      iconOn: usersOnIcon,
      iconOff: usersOffIcon,
      sub: subNavItems,
    },
  ];

  return (
    <div>
      <NavMenu items={navItems} vertical defaultIndex={0} />
    </div>
  );
};

export default Home;
