import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import 'core-js/features/array/flat-map';
import 'core-js/features/map';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'raf/polyfill';
import 'whatwg-fetch';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app';
import { persistor, store } from './app/store';

const root = ReactDOM.createRoot(document.getElementById('app-root') as HTMLElement);

root.render(
  <div>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </div>,
);
