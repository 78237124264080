import { BrowserCacheLocation, LogLevel, RedirectRequest } from '@azure/msal-browser';
import config from '../../../config/configApp.json';

export const consoleTextStyles = {
  Title: 'background: SteelBlue; color: #000',
  Unset: 'background: unset; color:unset',
};

export const msalConfig = {
  auth: {
    clientId: config.auth.clientId,
    authority: `https://${config.auth.loginDomain}/${config.auth.tenantName}.onmicrosoft.com/${config.auth.policies.login}`,
    knownAuthorities: [config.auth.loginDomain],
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }

        const logEntry = `%cMSAL%c ${message}`;

        switch (level) {
          case LogLevel.Error:
            console.error(logEntry);
            break;
          case LogLevel.Info:
            console.info(logEntry);
            break;
          case LogLevel.Verbose:
            console.debug(logEntry);
            break;
          case LogLevel.Warning:
            console.warn(logEntry);
            break;
          default: {
            break;
          }
        }
      },
    },
  },
};

export const authScopes = {
  wallachiaUserStandard: config.auth.scopes.wallachiaUserStandard,
  senseiDefault: config.auth.scopes.senseiDefault,
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [authScopes.wallachiaUserStandard],
};
