import Home from './Home';
import Users from './Users';
import Locations from './Locations';
import NotAuthorized from './NotAuthorized';
import Login from './Login';
import SelectTenant from './SelectTenant';

export default {
  Home,
  Users,
  Locations,
  NotAuthorized,
  Login,
  SelectTenant,
};
