import React from 'react';
import { PERMISSIONS } from './roleDefinitions';
import { getSelectedTenant } from '../../features/tenants/tenantsSlice';
import { useAppSelector } from '../../common/hooks/state';
import { IUserTenantInstallation } from '../../features/tenants/tenantsTypes';

const hasPermission = (selectedTenant: IUserTenantInstallation | null, scopes: string[]): boolean => {
  if (selectedTenant) {
    const permissions = PERMISSIONS[selectedTenant.wallachiaUserRole];
    const scopesMap: Map<string, boolean> = new Map();
    scopes.forEach((scope) => {
      scopesMap.set(scope, true);
    });

    return permissions.some((permission) => scopesMap.get(permission));
  }
  return false;
};

interface IProps {
  children: React.ReactNode;
  scopes: string[];
  renderError?: React.ReactNode;
}

const permissionGate: React.FC<IProps> = ({ children, scopes, renderError = () => <></> }) => {
  // Check if the persmissions associated with the users role
  // contain the requested scope

  // NOTE: The role is now stored against the UserTenantInstallation
  // which allows the User to have different roles per Tenant Installation
  const selectedTenant = useAppSelector((state) => getSelectedTenant(state));

  if (selectedTenant) {
    const permissionGranted = hasPermission(selectedTenant, scopes);

    if (!permissionGranted) return (<>{renderError}</>);

    return <>{children}</>;
  }
  return (<>{renderError}</>);
};

// eslint-disable-next-line no-restricted-exports
export { permissionGate as default, hasPermission };
