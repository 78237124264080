export interface IAuthState {
  token: string
  senseiToken: string
  loading: boolean
  success: boolean
  error: boolean
  timedOut: boolean
}

export interface ISignInSuccessPayload {
  token: string
  senseiToken: string
}

export interface ISignOutPayload {
  timedOut: boolean
}

/* eslint-disable no-unused-vars */
export enum AuthStates {
  LOADING,
  SUCCESS,
  ERROR,
  TIMEDOUT
}
