import { apiSlice } from '../../app/api/apiSlice';
import endPoints from '../../app/api/endpoints';
import { IHealthCheck } from './rootTypes';

/** Inject the root api */
export const rootApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Root'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      healthCheck: builder.query<IHealthCheck, void>({
        query: () => `${endPoints.root.healthCheck}`,
      }),
    }),
  });

// Action creators are generated for each case reducer function
export const {
  useHealthCheckQuery,
} = rootApi;
