import { apiSlice } from '../../app/api/apiSlice';
import endPoints from '../../app/api/endpoints';
import { IAccount, IAccountSettings } from './accountTypes';

export const accountApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Account', 'Settings'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAccount: builder.query<IAccount, void>({
        query: () => endPoints.account.root,
        transformResponse: (responseData: IAccount) => responseData,
        providesTags: ['Account'],
      }),
      getAccountSettings: builder.query<IAccountSettings, void>({
        query: () => endPoints.account.getAccountSettings,
        transformResponse: (responseData: IAccountSettings) => responseData,
        providesTags: ['Settings'],
      }),
      updateAccountSettings: builder.mutation<IAccountSettings, Partial<IAccountSettings>>({
        query(data) {
          const { ...body } = data;
          return {
            url: endPoints.account.updateAccountSettings,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: ['Settings'],
      }),
    }),
  });

// Action creators are generated for each case reducer function
export const {
  useGetAccountQuery,
  useGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation,
} = accountApi;
