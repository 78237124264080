import React, { useEffect } from 'react';
import { Button } from '@csdental/react-components';
import { useTranslation } from 'react-i18next';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import appLogo from '../../assets/images/csd-logo.svg';
import './SelectTenant.scss';
import { useAppDispatch, useAppSelector } from '../../common/hooks/state';
import { useGetUserTenantInstallationsQuery } from '../../features/tenants/tenantsAPI';
import { getSelectedTenant, setAvailableTenants, setSelectedTenant } from '../../features/tenants/tenantsSlice';
import NotAuthorized from '../NotAuthorized';
import { IUserTenantInstallation } from '../../features/tenants/tenantsTypes';
import { getCurrentAuthState } from '../../features/auth/authSlice';
import { AuthStates } from '../../features/auth/authTypes';

type SelectTenantProps = {
  children: React.ReactNode
}

const SelectTenant = ({ children }: SelectTenantProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedTenantInstallationId, setSelectedTenantInstallationId] = React.useState('');

  const { data: availableTenants, isLoading: isLoadingTenants } = useGetUserTenantInstallationsQuery();

  const selectedTenant = useAppSelector((state) => getSelectedTenant(state));

  const setSetTenantInstallation = (tenant: IUserTenantInstallation) => {
    dispatch(setSelectedTenant({ ...tenant }));
  };

  useEffect(() => {
    if (availableTenants && availableTenants.length > 0) {
      dispatch(setAvailableTenants({ ...availableTenants }));
      if (availableTenants.length === 1) {
        setSetTenantInstallation(availableTenants[0]);
      }
    }
  }, [availableTenants]);

  // Check the current auth state
  const authState = useAppSelector((state) => getCurrentAuthState(state));

  if (authState !== AuthStates.SUCCESS) {
    return (
      <div>Loading...</div>
    );
  }

  if (isLoadingTenants) {
    return (
      <div>{t('Loading Tenants...')}</div>
    );
  }

  if (!availableTenants || availableTenants.length === 0) {
    const message = t('You are not authorized to log into this portal');
    return <NotAuthorized message={message} />;
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tenantInstallationId: string,
  ) => {
    setSelectedTenantInstallationId(tenantInstallationId);
  };

  const handleSelectTenantClick = () => {
    const tenantInstallation = availableTenants.find((ti) => ti.recordId === selectedTenantInstallationId);

    if (tenantInstallation) setSetTenantInstallation(tenantInstallation);
  };

  const teanantInstallationListItems = availableTenants.map((tenantInstallation) => (
    <ListItemButton
      selected={selectedTenantInstallationId === tenantInstallation.recordId}
      onClick={(event) => handleListItemClick(event, tenantInstallation.recordId)}
    >
      <ListItemText
        primary={tenantInstallation.installationName}
        secondary={t(tenantInstallation.wallachiaUserRole)}
      />
    </ListItemButton>
  ));

  return (
    <div>
      {!selectedTenant && (
        <div className="selectTenant">
          <div className="csdFlow">
            <div className="centered" style={{ flex: 1, flexDirection: 'column' }}>
              <div>
                <img
                  src={appLogo}
                  alt="Carestream Dental Logo"
                  style={{ width: 370 }}
                />
              </div>
              <h3 className="csd-purple" style={{ paddingTop: 50 }}>
                {t('Web Admin Portal')}
              </h3>
              <section style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}>
                <Typography>
                  <strong>{t('Please select a tenant installation to continue')}</strong>
                </Typography>
              </section>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                  width: '100%',
                  maxWidth: 500,
                  bgcolor: 'background.paper',
                  p: 2,
                  border: '1px solid grey',
                  borderRadius: '10px',
                }}
                >
                  <List dense>
                    {teanantInstallationListItems}
                  </List>
                </Box>
              </div>
              <div style={{ paddingTop: 40 }}>
                <Button
                  disable={selectedTenantInstallationId === ''}
                  label={t('Select Tenant')}
                  onClick={handleSelectTenantClick}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedTenant && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
};

export default SelectTenant;
