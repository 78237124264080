import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserTenantInstallation } from './tenantsTypes';
import { RootState } from '../../app/store';

interface TenantState {
  availableTenants: IUserTenantInstallation[] | null;
  selectedTenant: IUserTenantInstallation | null;
}

const initialState : TenantState = {
  availableTenants: null,
  selectedTenant: null,
};

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setAvailableTenants: (state:TenantState, action: PayloadAction<any>) => {
      state.availableTenants = { ...action.payload };
    },
    setSelectedTenant: (state:TenantState, action: PayloadAction<any>) => {
      state.selectedTenant = { ...action.payload };
    },
    resetTenants: (state) => {
      state.availableTenants = null;
      state.selectedTenant = null;
    },
  },
});

export const { setAvailableTenants, setSelectedTenant, resetTenants } = tenantsSlice.actions;
export default tenantsSlice.reducer;

export const getAvailableTenants = (state: RootState) => state.tenants.availableTenants;
export const getSelectedTenant = (state: RootState) => state.tenants.selectedTenant;
