import { Button } from '@csdental/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks/state';
import authService from '../../features/auth/authService';
import { signOut } from '../../features/auth/authSlice';
import alertIcon from '../../assets/images/alert.svg';
import appLogo from '../../assets/images/csd-logo.svg';
import './NotAuthorized.scss';
import { resetTenants } from '../../features/tenants/tenantsSlice';

const NotAuthorized = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(resetTenants());
    dispatch(signOut({ timedOut: false }));
    authService.signOut();
  };

  return (
    <div className="notAuth">
      <div className="csdFlow">
        <div className="centered" style={{ flex: 1, flexDirection: 'column' }}>
          <div>
            <img
              src={appLogo}
              alt="Carestream Dental Logo"
              style={{ width: 370 }}
            />
          </div>
          <h3 className="csd-purple" style={{ paddingTop: 50 }}>
            {t('Web Admin Portal')}
          </h3>
          <div className="alert" style={{ marginTop: '20px' }}>
            <img
              className="alert-icon"
              src={alertIcon}
              alt="Warning"
            />
            {message}
          </div>
          <div style={{ paddingTop: 40 }}>
            <Button
              label={t('Logout')}
              onClick={logout}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
