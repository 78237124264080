import { IRecordBase } from '../../common/types/recordTypes';

/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum AddressTypeCodes{
  UNSPECIFIED = 'Unspecified',
  MAIN = 'Main',
  HOME = 'Home',
  WORK = 'Work',
  PHYSICAL = 'Physical',
  BILLING = 'Billing',
  EMERGENCY = 'Emergency',
  OUTOFOFFICE = 'OutOfOffice',
}

export interface ICountry {
  countryId: number
  countryCode: string
  countryName: string
  addressFormat: string
}

export interface IStateProvince {
  stateProvinceId: number
  countryId: number
  stateProvinceCode: string
  stateProvinceName: string
  country: ICountry
}

export interface IAddressRecord extends IRecordBase {
  addressBlock: string
  cityStateZipCode: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  addressTypeCode: AddressTypeCodes
  statesProvince: IStateProvince
}

export interface ILocationRecord extends IRecordBase {
  internalName: string
  businessName: string
  practiceEmail: string
  addresses: IAddressRecord[]
}
