import React, { useState } from 'react';
import { Header } from '@csdental/react-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks/state';
import { signOut } from '../../features/auth/authSlice';
import authService from '../../features/auth/authService';
import {
  useGetAccountQuery,
  useGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation,
} from '../../features/account/accountApi';
import config from '../../../config/configApp.json';
import appLogo from '../../assets/images/cd-app-logo.svg';
import headerBackground from '../../assets/images/header-bg.png';
import AppInfoDlg from '../../components/AppInfoDlg';
import { IHealthCheckProps } from '../../common/types/dialogTypes';
import { useHealthCheckQuery } from '../../features/root/rootAPI';
import { resetTenants } from '../../features/tenants/tenantsSlice';

export function getUserInitials(name: string) {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase();
}

const HeaderComponent: React.FC = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language.slice(0, 2);

  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();
  const { data: settings, isLoading: isSettingsLoading } = useGetAccountSettingsQuery();

  // Update User Settings Api trigger
  const [updateSettingsTrigger] = useUpdateAccountSettingsMutation();

  const dispatch = useAppDispatch();

  // Show application info dialog
  const [showAppInfoDlg, setShowAppInfoDlg] = useState<boolean>(false);

  const logout = () => {
    dispatch(resetTenants());
    dispatch(signOut({ timedOut: false }));
    authService.signOut();
  };

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    if (settings) {
      updateSettingsTrigger({ ...settings, language: code });
    }
  };

  // Set the current language
  if (settings?.language && settings?.language !== currentLanguage) {
    i18n.changeLanguage(settings.language);
  }

  const avatar = (): string => {
    if (account) {
      return account.recordId !== null && account.recordId !== undefined
        ? `${account?.firstName?.charAt(0)}${account?.lastName?.charAt(0)}` : 'U';
    }
    return 'U';
  };

  const accountInfo = {
    userDisplayName: `${account?.firstName} ${account?.lastName}`,
    infoLabel: t('Info'),
    logoutLabel: t('Logout'),
    userAvatar: avatar(),
    onOpenAppInfo: () => setShowAppInfoDlg(true),
    onLogout: logout,
  };

  // Call the API to get health check
  const healthCheck = useHealthCheckQuery();

  const appConfigInfo: IHealthCheckProps = {
    frontVersion: config.config.version,
    apiVersion: healthCheck.data ? healthCheck.data.build : 'undefined',
    commitHash: config.config.gitHash,
    frontBuildDate: config.config.releaseDate,
  };

  const languagesInfo = {
    languages: [
      {
        value: 'English',
        code: 'en',
      },
      {
        value: 'Français',
        code: 'fr',
      },
    ],
    currentLanguage,
    OnChangeLanguage: changeLanguage,
  };

  if (isSettingsLoading) {
    return <div />;
  }

  return (
    <>
      <Header
        branding="CSD"
        applicationName="Wallachia User Administration Tool"
        logo={appLogo}
        backgroundImage={headerBackground}
        accountProps={accountInfo}
        languagesProps={languagesInfo}
      />
      <AppInfoDlg
        healthCheckInfo={appConfigInfo}
        show={showAppInfoDlg}
        onClose={() => setShowAppInfoDlg(false)}
      />
    </>
  );
};

export default HeaderComponent;
