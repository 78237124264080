// eslint-disable-next-line no-shadow, no-unused-vars, max-classes-per-file
export enum ROLES {
  // eslint-disable-next-line no-unused-vars
  Unknown = 'Unknown',
  // eslint-disable-next-line no-unused-vars
  User = 'User',
  // eslint-disable-next-line no-unused-vars
  AccountOwner = 'AccountOwner',
  // eslint-disable-next-line no-unused-vars
  Administrator = 'Administrator',
}

// Define Default Scopes
class DefaultScopes {
  static readonly canCreate: string = 'can-create';

  static readonly canEdit: string = 'can-edit';

  static readonly canDelete: string = 'can-delete';

  static readonly canView: string = 'can-view';
}

// Define Users Scopes
export class UsersScopes extends DefaultScopes {
  static readonly ManageAdministrators: string = 'manage-administrators-scope';
}

// Define Locations Scopes
export class LocationsScopes extends DefaultScopes {
  static readonly ManageLocations: string = 'manage-locations-scope';
}

// Map scopes to roles
export const PERMISSIONS = {
  [ROLES.User.valueOf()]: [
    UsersScopes.canView,
  ],
  [ROLES.AccountOwner.valueOf()]: [
    UsersScopes.canView,
    UsersScopes.canEdit,
    UsersScopes.canCreate,
    UsersScopes.canDelete,
    UsersScopes.ManageAdministrators,
    LocationsScopes.canView,
    LocationsScopes.canEdit,
    LocationsScopes.canCreate,
    LocationsScopes.canDelete,
    LocationsScopes.ManageLocations,
  ],
  [ROLES.Administrator.valueOf()]: [
    UsersScopes.canView,
    UsersScopes.canEdit,
    UsersScopes.canCreate,
    UsersScopes.canDelete,
  ],
};
