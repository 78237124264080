import config from '../../../config/configApp.json';

// Define the API endpoints
const endPoints = {
  root: {
    healthCheck: 'healthcheck',
  },
  account: {
    root: `${config.api.api}Account`,
    getAccountSettings: `${config.api.api}Account/Settings`,
    updateAccountSettings: `${config.api.api}Account/UpdateSettings`,
  },
  users: {
    root: `${config.api.api}Users`,
    getUsers: `${config.api.OData}Users`,
  },
  locations: {
    root: `${config.api.api}Locations`,
    getLocations: `${config.api.OData}Locations`,
  },
  tenants: {
    root: `${config.api.api}Tenants`,
    getUserTenantInstallations: `${config.api.api}Tenants/GetTenantInstallationsForUser`,
  },
};

export default endPoints;
