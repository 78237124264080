import { apiSlice } from '../../app/api/apiSlice';
import endPoints from '../../app/api/endpoints';
import { TypeValueCount } from '../../common/types/queryTypes';
import { IUserRecord } from './usersTypes';

/** Inject the users api */
export const usersApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['User'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<TypeValueCount<IUserRecord>,
        { skip: number, top: number, filter?: string }>({
          query: (args) => {
            let query = `?$count=true
            &$expand=Location($expand=Addresses($expand=StatesProvince($expand=Country)))
            &$skip=${args.skip}&$top=${args.top}&$orderby=RecordCreated desc`;

            if (args.filter) {
              const valueUpperCase = args.filter?.toUpperCase();
              query += `&$filter=IsSystemUser eq false and
              (contains(FirstName,'${valueUpperCase}') or contains(LastName,'${valueUpperCase}'))`;
            } else {
              query += '&$filter=IsSystemUser eq false';
            }

            return `${endPoints.users.getUsers}${query}`;
          },
          providesTags: (result) => (result?.value
            ? [...result.value.map(({ recordId }) => ({ type: 'User', recordId } as const)),
              { type: 'User', id: 'LIST' }]
            : [{ type: 'User', id: 'LIST' }]),
          transformResponse: (responseData: TypeValueCount<IUserRecord>) => responseData,
        }),
      addUser: builder.mutation<IUserRecord, Partial<IUserRecord>>({
        query: (body) => ({
          url: endPoints.users.root,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'User', id: 'LIST' }],
      }),
      updateUser: builder.mutation<IUserRecord, Partial<IUserRecord>>({
        query(data) {
          const { ...body } = data;
          return {
            url: `${endPoints.users.root}/${data.recordId}`,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: (user) => [{ type: 'User', id: user?.recordId }, { type: 'User', id: 'LIST' }],
      }),
    }),
  });

// Action creators are generated for each case reducer function
export const {
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
} = usersApi;
