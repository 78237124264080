import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../../config/configApp.json';
import authService from '../../features/auth/authService';
import { signInSuccess, signOut } from '../../features/auth/authSlice';
import type { RootState } from '../store';

/** Set the base query with default headers */
const baseQuery = fetchBaseQuery({
  baseUrl: config.api.host,
  prepareHeaders: (headers, { getState }) => {
    // Get the access token from state
    const { token, senseiToken } = (getState() as RootState).auth;
    // Set the Wallachia Auth Token
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    // Set the Sensei Auth Token
    if (senseiToken) {
      headers.set('X-SenseiAuth', `${senseiToken}`);
    }

    // Get the selected tenant from state
    const { selectedTenant } = (getState() as RootState).tenants;

    if (selectedTenant && selectedTenant.recordId !== '') {
      // Set the TenantInstallationId header
      headers.set('X-TenantInstallationID', `${selectedTenant.recordId}`);
    }
    headers.set('Content-Type', 'application/json;charset=utf-8');
    return headers;
  },
});

/** Base query to refresh the auth token on failure */
const baseQueryWithRefresh = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log('Refreshing the auth token');

    const refreshResult = await authService.getWallachiaAuthToken();

    if (refreshResult) {
      // Also refresh the sensei token
      const senseiToken = await authService.getSenseiAuthToken();

      if (senseiToken) {
        // Update the auth state
        api.dispatch(signInSuccess({ token: refreshResult, senseiToken: (senseiToken) || '' }));
        // Rerun the query with the newly acquired token
        result = await baseQuery(args, api, extraOptions);
      } else {
        // Failed to get a new sensei token so log out
        api.dispatch(signOut({ timedOut: false }));
      }
    } else {
      // Failed to get a new wallachia token so log out
      api.dispatch(signOut({ timedOut: false }));
    }
  }
  return result;
};

/** Define our single API slice object */
export const apiSlice = createApi({
  baseQuery: baseQueryWithRefresh,
  // The "endpoints" represent operations and requests for this server
  // We will extend the API slice by injecting endpoints from features
  endpoints: () => ({}),
});

export default apiSlice;
