/* eslint-disable max-len */
import { apiSlice } from '../../app/api/apiSlice';
import endPoints from '../../app/api/endpoints';
import { TypeValueCount } from '../../common/types/queryTypes';
import { ILocationRecord } from './locationsTypes';

/** Inject the locations api */
export const locationsApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Location'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLocations: builder.query<TypeValueCount<ILocationRecord>,
        { skip: number, top: number, filter?: string }>({
          query: (args) => {
            const query = `?$count=true
            &$expand=Addresses($expand=StatesProvince($expand=Country))
            &$skip=${args.skip}&$top=${args.top}&$orderby=RecordCreated desc`;

            return `${endPoints.locations.getLocations}${query}`;
          },
          providesTags: (result) => (result?.value
            ? [...result.value.map(({ recordId }) => ({ type: 'Location', recordId } as const)),
              { type: 'Location', id: 'LIST' }]
            : [{ type: 'Location', id: 'LIST' }]),
          transformResponse: (responseData: TypeValueCount<ILocationRecord>) => responseData,
        }),
      addLocation: builder.mutation<ILocationRecord, Partial<ILocationRecord>>({
        query: (body) => ({
          url: endPoints.locations.root,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'Location', id: 'LIST' }],
      }),
      updateLocation: builder.mutation<ILocationRecord, Partial<ILocationRecord>>({
        query(data) {
          const { ...body } = data;
          return {
            url: endPoints.locations.root,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: (location) => [{ type: 'Location', id: location?.recordId }, { type: 'Location', id: 'LIST' }],
      }),
      deleteLocation: builder.mutation<{ success: boolean; id: string }, string>({
        query(id) {
          return {
            url: `${endPoints.locations.root}?locationId=${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: (location) => [{ type: 'Location', id: location?.id }, { type: 'Location', id: 'LIST' }],
      }),
    }),
  });

// Action creators are generated for each case reducer function
export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationsApi;
