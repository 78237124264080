import { apiSlice } from '../../app/api/apiSlice';
import endPoints from '../../app/api/endpoints';
import { IUserTenantInstallation } from './tenantsTypes';

/** Inject the tenants api */
export const tenantsApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Tenant'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserTenantInstallations: builder.query<IUserTenantInstallation[], void>({
        query: () => endPoints.tenants.getUserTenantInstallations,
        providesTags: [{ type: 'Tenant', id: 'LIST' }],
        transformResponse: (responseData: IUserTenantInstallation[]) => responseData,
      }),
    }),
  });

// Action creators are generated for each case reducer function
export const {
  useGetUserTenantInstallationsQuery,
} = tenantsApi;
