/* eslint-disable no-unused-vars */

/** Add/Edit user Dialog */
export interface IAddEditDlgProps<T> {
  /**
   * Data to pass to the dialog
   */
  data: Partial<T>
  /**
   * Show dialog
   */
  show: boolean
  /**
   * Call on validate
   */
  // eslint-disable-next-line no-unused-vars
  onValidate: (data: Partial<T>) => void
  /**
   * call on cancel
   */
  onCancel: () => void
  /**
   * error content
   */
  error?: string
  /**
   * If true display Edit theme
   */
  isEditMode?: boolean
  /**
   * True if the record is in context
   */
  isCurrent?: boolean
}

// Dialog State Actions
export enum AddEditDlgStateActions {
  RESET = 'RESET',
  ERROR = 'ERROR',
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export type AddEditDlgStateActionsType<T> =
  | { type: AddEditDlgStateActions.RESET }
  | { type: AddEditDlgStateActions.ERROR; error: string }
  | {
    type: AddEditDlgStateActions.ADD;
    onValidate: (data: Partial<T>) => void,
    onCancel: () => void
  }
  | {
    type: AddEditDlgStateActions.EDIT;
    onValidate: (data: Partial<T>) => void,
    onCancel: () => void;
    data: Partial<T>;
    isCurrent: boolean
  };

/** Application Info Dialog */
export interface IHealthCheckProps {
  /**
    Frontend tag
   */
  frontVersion: string;
  /**
   * Backend tag
   */
  apiVersion: string;
  /**
   * Commit hash in Git for frontend
   */
  commitHash: string;
  /**
   * Build date for frontend
   */
  frontBuildDate: string;
}

export interface IAppInfoDlgProps {
  /**
   * HealthCheckInfo to pass to the dialog
   */
  healthCheckInfo: IHealthCheckProps
  /**
   * Show dialog
   */
  show: boolean
  /**
   * Called on close
   */
  onClose: () => void
}
